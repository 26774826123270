const G = 'G';
const Svg = 'SVG';
const View = 'VIEW';
const Text = 'TEXT';
const Link = 'LINK';
const Page = 'PAGE';
const Note = 'NOTE';
const Path = 'PATH';
const Rect = 'RECT';
const Line = 'LINE';
const FieldSet = 'FIELD_SET';
const TextInput = 'TEXT_INPUT';
const Select = 'SELECT';
const Checkbox = 'CHECKBOX';
const List = 'LIST';
const Stop = 'STOP';
const Defs = 'DEFS';
const Image = 'IMAGE';
const Tspan = 'TSPAN';
const Canvas = 'CANVAS';
const Circle = 'CIRCLE';
const Ellipse = 'ELLIPSE';
const Polygon = 'POLYGON';
const Document = 'DOCUMENT';
const Polyline = 'POLYLINE';
const ClipPath = 'CLIP_PATH';
const TextInstance = 'TEXT_INSTANCE';
const LinearGradient = 'LINEAR_GRADIENT';
const RadialGradient = 'RADIAL_GRADIENT';

export { Canvas, Checkbox, Circle, ClipPath, Defs, Document, Ellipse, FieldSet, G, Image, Line, LinearGradient, Link, List, Note, Page, Path, Polygon, Polyline, RadialGradient, Rect, Select, Stop, Svg, Text, TextInput, TextInstance, Tspan, View };
